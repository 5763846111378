import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { client } from "./client";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const [generalData, setGeneralData] = useState({});

    const isWidescreen = useMediaQuery({
        query: "(min-width: 1500px)",
    });
    const isMobile = useMediaQuery({
        query: "(max-width: 800px)",
    });

    const links = [
        { name: "About", url: "/" },
        { name: "Projects", url: "/projects" },
        { name: "Contact", url: "/contact" },
    ];

    const socials = [
        { title: "LinkedIn", url: "http://linkedin.com/in/dharris11" },
        { title: "GitHub", url: "http://github.com/zerossive" },
    ];

    useEffect(() => {
        // Get general data
        client
            .getEntries({
                content_type: "general",
            })
            .then((response) => {
                const { photo, background, logo } = response.items[0].fields;
                setGeneralData({ photo, background, logo });
            })
            .catch(console.error);
    }, []);

    return (
        <AppContext.Provider
            value={{ generalData, links, socials, isWidescreen, isMobile }}
        >
            {children}
        </AppContext.Provider>
    );
};
// make sure use
export const useGlobalContext = () => {
    return useContext(AppContext);
};

export { AppContext, AppProvider };
