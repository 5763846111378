import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
// Page imports
import About from "./pages/About";
import Projects from "./pages/Projects";
import ProjectDetailed from "./pages/ProjectDetailed";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import HexagonGrid from "./components/HexagonGrid";

function App() {
	return (
		<Router>
			<Switch>
				<Route exact path='/'>
					<About />
					<HexagonGrid />
				</Route>
				<Route exact path='/projects'>
					<Projects />
					<HexagonGrid />
				</Route>
				<Route exact path='/contact'>
					<Contact />
					<HexagonGrid />
				</Route>
				<Route
					path='/projects/:projectId'
					component={ProjectDetailed}
				/>
				<Route path='*'>
					<Error />
					<HexagonGrid />
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
