import React, { useState } from "react";
import Navbar from "../components/Navbar";
import emailjs from "emailjs-com";
import { useGlobalContext } from "../context";

const Contact = () => {
	const { socials } = useGlobalContext();

	const [contactNotif, setContactNotif] = useState("");

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_3649z97",
				"template_8ry05re",
				e.target,
				"user_XauhbreBLfDA1556zFwvO"
			)
			.then(
				(result) => {
					console.log(result.text);
					setContactNotif("Submitted Successfully");
				},
				(error) => {
					console.log(error.text);
				}
			);

		e.target.reset();
	};

	// Automatically expands textareas
	const autoGrow = (e) => {
		e.target.style.height = "0px";
		e.target.style.height = e.target.scrollHeight + 2 + "px";
	};

	return (
		<>
			<Navbar data={{ title: "Contact", photo: false, logo: false }} />
			<main className='container mx-auto flex flex-col gap-6 p-6'>
				{/* Contact Form */}
				<div className='card flex flex-col gap-6 p-6'>
					<h2>Contact Me</h2>
					<form
						className='flex max-w-full flex-wrap gap-6'
						onSubmit={sendEmail}
					>
						{/* Name */}
						<input
							type='text'
							className='input w-full lg:w-auto lg:flex-grow'
							placeholder='Your Name'
							name='name'
							required
						/>
						{/* Email */}
						<input
							type='text'
							className='input w-full lg:w-auto lg:flex-grow'
							placeholder='Your Email Address'
							name='email'
							required
						/>
						{/* Message */}
						<textarea
							type='text'
							className='input min-h-[100px] w-full resize-none'
							placeholder='Your Message'
							name='message'
							onInput={(e) => {
								autoGrow(e);
							}}
							required
						/>
						{/* Submit */}
						<input
							type='submit'
							className='btn btn-cta w-full'
							value='Submit'
							onClick={() => setContactNotif("")}
						/>
						{contactNotif && (
							<div
								className='w-full cursor-pointer'
								onClick={() => setContactNotif(false)}
							>
								<p className='rounded-lg bg-green-500 p-3 text-center text-white'>
									{contactNotif}
								</p>
							</div>
						)}
					</form>
				</div>
				{/* Socials */}
				<div className='anim-fade-in flex justify-center gap-6'>
					{socials.map((social, index) => {
						const { title, url } = social;

						return (
							<div className='' key={index}>
								<a href={url}>
									<button className='btn btn-cta'>
										{title}
									</button>
								</a>
							</div>
						);
					})}
				</div>
			</main>
		</>
	);
};

export default Contact;
