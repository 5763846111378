import React, { useState, useEffect } from "react";
import { useGlobalContext } from "../context";
import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import CornerCurve from "./CornerCurve";
import { useAutoAnimate } from "@formkit/auto-animate/react";

function Navbar({ data }) {
	const { generalData, links, isMobile } = useGlobalContext();

	const { title, photo, logo } = data;

	const [showNavMobile, setShowNavMobile] = useState(false);

	const [navAnimParent] = useAutoAnimate();

	// Close mobile nav menu on switching to desktop
	useEffect(() => {
		if (!isMobile && showNavMobile) setShowNavMobile(false);
	}, [isMobile, showNavMobile]);

	// Scroll direction capturing
	const [scroll1, setScroll1] = useState(0);
	const [scroll2, setScroll2] = useState(0);
	const [scrollWait, setScrollWait] = useState(false);
	const [scrollingUp, setScrollingUp] = useState(true);
	window.onscroll = () => {
		if (!scrollWait) {
			isMobile ? setScroll1(window.scrollY) : setScroll2(window.scrollY);

			setScrollWait(true);
			setTimeout(() => {
				isMobile
					? setScroll2(window.scrollY)
					: setScroll1(window.scrollY);
				setScrollWait(false);
				// On scroll down
				scroll2 - scroll1 < 0 && setScrollingUp(true);
				// On scroll up
				scroll2 - scroll1 > 0 && setScrollingUp(false);
				// Backup check if at top
				window.scrollY === 0 && setScrollingUp(true);
			}, 100);
		}
	};

	return (
		<>
			<nav
				className='relative w-full bg-primary-3 bg-fixed bg-center font-bold'
				style={{
					backgroundImage: `url(${
						generalData.background &&
						generalData.background.fields.file.url
					}?w=400&h=400&fm=webp)`,
				}}
				ref={navAnimParent}
			>
				<div className='w-full backdrop-blur'>
					{/* Desktop Images */}
					{generalData.photo && photo && (
						<img
							src={`${generalData.photo.fields.file.url}?w=300&h=300&fm=webp`}
							alt='headshot'
							className='anim-grow-y anim-ease absolute left-0 -bottom-14 ml-12 hidden aspect-square max-h-[125%] w-auto origin-center rounded-xl border-[5px] border-solid border-white object-cover object-top'
							onLoad={(e) =>
								e.target.classList.add("xl:inline-block")
							}
						/>
					)}
					{generalData.logo && logo && (
						<img
							src={`${generalData.logo.fields.file.url}?w=300&h=300&fm=webp`}
							alt='logo'
							className='anim-grow-y anim-ease absolute -bottom-14 right-0 mr-12 hidden aspect-square max-h-[125%] w-auto origin-center object-cover object-top'
							onLoad={(e) =>
								e.target.classList.add("xl:inline-block")
							}
						/>
					)}

					{/* Desktop Title */}
					{!isMobile && (
						<div className='flex flex-row justify-center pt-12'>
							{links.map((link) => {
								const { name, url } = link;

								return (
									<div className='relative' key={name}>
										<CornerCurve
											side='left'
											size='10px'
											check1={name}
											check2={title}
										/>
										<Link
											to={url}
											onClick={() => {
												window.scrollTo({
													top: 0,
													behavior: "smooth",
												});
											}}
										>
											<span
												className={`flex rounded-t-xl px-12 py-2 text-lg text-white hover:text-blue-500 ${
													name === title &&
													"bg-primary-6 text-primary-1 dark:bg-secondary-5 dark:text-secondary-1"
												}`}
											>
												{name}
											</span>
										</Link>
										<CornerCurve
											side='right'
											size='10px'
											check1={name}
											check2={title}
										/>
									</div>
								);
							})}
						</div>
					)}
				</div>
			</nav>
			{/* Mobile Menu Button */}
			{isMobile && (
				<button
					className={`sticky top-0 z-10 grid h-[60px] w-full cursor-pointer grid-cols-3 items-center justify-between border-none bg-primary-1 font-bold text-white ${
						scrollingUp ? "anim-grow-y" : "anim-shrink-y"
					} anim-fast dark:bg-secondary-4`}
				>
					{generalData.logo && (
						<Link
							to='/'
							className='flex justify-start pl-3'
							onClick={() => {
								window.scrollTo({
									top: 0,
									behavior: "smooth",
								});
								setShowNavMobile(false);
							}}
						>
							<img
								src={`${generalData.logo.fields.file.url}?w=400&h=400&fm=webp`}
								alt='mobile logo'
								className='aspect-square h-[60px] w-auto p-3'
							/>
						</Link>
					)}
					<h3
						className='flex-grow'
						onClick={() => {
							window.scrollTo({
								top: 0,
								behavior: "smooth",
							});
							setShowNavMobile(false);
						}}
					>
						{title}
					</h3>
					<div
						className='flex justify-end p-3 pr-6'
						onClick={() => setShowNavMobile(!showNavMobile)}
					>
						<FaBars className='' size='1.5rem' />
					</div>
				</button>
			)}
			{/* Mobile Page Links */}
			{isMobile && showNavMobile && (
				<>
					{/* Nav */}
					<div
						className={`anim-grow-y anim-fast fixed top-0 left-0 z-10 h-auto w-full origin-top overflow-hidden rounded-b-3xl bg-primary-1 font-bold dark:bg-secondary-4`}
					>
						{/* Close nav button */}
						<button
							className='sticky top-0 z-10 flex w-full cursor-pointer justify-center border-none bg-primary-1 p-6 text-primary-6 dark:bg-secondary-4'
							onClick={() => setShowNavMobile(!showNavMobile)}
						>
							<FaTimes size='1.5rem' />
						</button>

						{/* list of nav links */}
						<div className='w-full'>
							{links.map((link) => {
								const { name, url } = link;
								return (
									<Link
										to={url}
										onClick={() => {
											window.scrollTo({
												top: 0,
												behavior: "smooth",
											});
											setShowNavMobile(false);
										}}
										className='flex cursor-pointer flex-col justify-center px-12'
										key={name}
									>
										<div className='h-[2px] w-full rounded-sm bg-primary-4 opacity-50'></div>
										<span
											className={`flex flex-row justify-center p-6 text-white ${
												name === title &&
												"text-primary-5"
											}`}
										>
											{name}
										</span>
									</Link>
								);
							})}
						</div>
					</div>

					{/* bg - click anywhere to close */}
					<div
						className={`anim-fade-in anim-fast fixed top-0 left-0 z-[5] h-screen w-full cursor-pointer bg-primary-2 bg-opacity-70 backdrop-blur`}
						onClick={() => setShowNavMobile(false)}
					></div>
				</>
			)}
		</>
	);
}

export default Navbar;
