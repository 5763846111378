import { React, useState, useEffect } from "react";
import { client } from "../client";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import Navbar from "../components/Navbar";
import ProjectCard from "../components/ProjectCard";

export default function Project() {
	const [projects, setProjects] = useState([]);
	const [filteredTag, setFilteredTag] = useState("All");

	let tagSet = [];
	projects.forEach((project) => {
		project.fields.tags.forEach((tag) => {
			if (!tagSet.includes(tag)) tagSet.push(tag);
		});
	});
	const tags = ["All", ...tagSet.sort()];

	useEffect(() => {
		// Get projects
		client
			.getEntries({
				content_type: "project",
			})
			.then((response) => {
				// Sort
				let sortedItems = response.items.sort((a, b) => {
					console.log(a);
					// Sort by creation date
					return a.sys.createdAt - b.sys.createdAt;
					// Sort by index
					// return a.fields.index - b.fields.index;
				});
				sortedItems.map((item) => {
					item.fields.longDescription = documentToHtmlString(
						item.fields.longDescription
					);
					return item;
				});
				setProjects(sortedItems);
			})
			.catch(console.error);
	}, []);

	return (
		<>
			<Navbar data={{ title: "Projects", photo: false, logo: false }} />
			<main className='container mx-auto grid gap-3 p-6 lg:gap-6'>
				{/* Tags */}
				{projects[0] && (
					<div className='flex flex-row gap-6 overflow-auto pb-3 lg:flex-wrap lg:pb-0'>
						{tags.map((tag) => {
							return (
								<button
									key={tag}
									className='btn'
									onClick={() => {
										setFilteredTag(tag);
									}}
								>
									{filteredTag === tag ? (
										<span className='text-primary-5'>
											{tag}
										</span>
									) : (
										tag
									)}
								</button>
							);
						})}
					</div>
				)}

				{/* Project Cards */}
				{projects[0] && (
					<div className='grid gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
						{projects.map((project, index) => {
							if (
								filteredTag === "All" ||
								project.fields.tags.includes(filteredTag)
							) {
								return (
									<ProjectCard key={index} data={project} />
								);
							}
							return null;
						})}
					</div>
				)}
			</main>
		</>
	);
}
