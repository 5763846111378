import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";

const Error = () => {
	return (
		<>
			<Navbar
				data={{
					title: "404",
					photo: false,
					logo: false,
				}}
			/>
			<main className='container mx-auto flex w-full flex-col items-center gap-6 p-6'>
				<h1>404</h1>
				<h2>Page Not Found</h2>
				<Link to='/'>
					<button className='btn'>Return to About</button>
				</Link>
			</main>
		</>
	);
};

export default Error;
