import { React, useState, useEffect } from "react";
import { client } from "../client";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import { FaAngleLeft } from "react-icons/fa";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import HexagonGrid from "../components/HexagonGrid";

export default function Project({ match }) {
	const {
		params: { projectId },
	} = match;

	const [projects, setProjects] = useState([]);
	const [showPreview, setShowPreview] = useState(false);
	const [bigImage, setBigImage] = useState({
		show: false,
		url: "",
		description: "",
	});

	const [animParent] = useAutoAnimate();

	useEffect(() => {
		// Get projects
		client
			.getEntries({
				content_type: "project",
			})
			.then((response) => {
				let sortedItems = response.items.sort((a, b) => {
					return a.fields.index - b.fields.index;
				});
				sortedItems.map((item) => {
					item.fields.longDescription = documentToHtmlString(
						item.fields.longDescription
					);
					return item;
				});
				setProjects(sortedItems);
			})
			.catch(console.error);
	}, []);

	return (
		<>
			<Navbar data={{ title: "Projects", photo: false, logo: false }} />
			<main className='container mx-auto flex flex-col gap-6 p-6'>
				{/* Back */}
				<Link
					to='/projects'
					className='flex items-center gap-0.5 text-primary-4 dark:text-primary-5'
				>
					<FaAngleLeft />
					<span>Back to Projects</span>
				</Link>
				{/* Project Details */}
				{projects.map((project, index) => {
					const { title, longDescription, tags, images, url } =
						project.fields;

					if (title === projectId) {
						return (
							<section
								key={index}
								className='anim-fade-in flex flex-row flex-wrap items-start justify-center gap-6 lg:justify-between lg:gap-0'
							>
								{/* Body */}
								<article
									dangerouslySetInnerHTML={{
										__html: longDescription,
									}}
									className='anim-fade-in anim-slow description flex flex-col gap-6 lg:w-2/3 lg:pr-6 lg:pb-6'
								></article>
								{/* Tags */}
								<div className='card w-full flex-col gap-6 p-6 lg:w-1/3'>
									<h3 className='text-center'>
										Technologies Used
									</h3>
									<div className='flex flex-wrap justify-center gap-3'>
										{tags.map((tag, index) => {
											return (
												<span
													className='rounded bg-black px-3 text-white dark:bg-secondary-1 dark:text-secondary-6'
													key={index}
												>
													{tag}
												</span>
											);
										})}
									</div>
								</div>
								{/* Links */}
								{url && (
									<>
										<div className='anim-fade-in flex w-full justify-center gap-6 lg:pb-6'>
											<a href={url}>
												<button className='btn btn-cta'>
													Visit Website
												</button>
											</a>
											<div>
												<button
													className='btn'
													onClick={() =>
														setShowPreview(
															!showPreview
														)
													}
												>
													{showPreview
														? "Hide Preview"
														: "Show Preview"}
												</button>
											</div>
										</div>
										{showPreview && (
											<iframe
												src={url}
												className='anim-grow-y mb-6 h-[600px] max-h-[80vh] w-full'
												title='Website Preview'
											></iframe>
										)}
									</>
								)}
								{/* Images */}
								<div className='flex w-full flex-row flex-wrap gap-6 lg:grid lg:grid-cols-2'>
									{images.map((image, index) => {
										return (
											<div
												className='relative hidden w-full'
												key={index}
												id={image.fields.title}
											>
												<img
													src={
														image.fields.file.url +
														"?w=800&h=600&fm=webp"
													}
													alt={image.fields.title}
													className='w-full cursor-zoom-in rounded-lg border-2 border-primary-1 dark:border-secondary-1 lg:aspect-video lg:object-cover lg:object-left-top'
													key={index}
													onClick={() => {
														setBigImage({
															show: true,
															url: image.fields
																.file.url,
															description:
																image.fields
																	.description,
														});
													}}
													onLoad={() => {
														const imgContainer =
															document.getElementById(
																image.fields
																	.title
															);

														imgContainer.classList.remove(
															"hidden"
														);

														imgContainer.classList.add(
															"anim-grow-fade"
														);
													}}
												/>
												<span className='anim-grow-fade pointer-events-none absolute top-0 right-0 m-2 rounded bg-white px-2 text-black shadow-md shadow-gray-500'>
													<span className='hidden md:inline-block'>
														click
													</span>
													<span className='md:hidden'>
														tap
													</span>{" "}
													for more info
												</span>
											</div>
										);
									})}
								</div>
							</section>
						);
					}
					return null;
				})}
				{/* Enlarged Image */}
				{bigImage.show && (
					<div
						className='fixed left-0 top-0 flex h-full w-full cursor-zoom-out flex-col items-center justify-center bg-primary-2 bg-opacity-70 backdrop-blur'
						onClick={() => setBigImage(false)}
						ref={animParent}
					>
						<p className='anim-fade-in p-3 text-primary-6'>
							<span className='hidden md:inline-block'>
								click
							</span>
							<span className='md:hidden'>tap</span> anywhere to
							close
						</p>
						<img
							src={bigImage.url + "?w=1280&h=720&fm=webp"}
							alt='enlarged'
							className='anim-grow-fade max-h-[75%] max-w-full object-contain'
						/>
						<p className='anim-fade-in p-3 text-primary-6'>
							{bigImage.description}
						</p>
					</div>
				)}
			</main>
			<HexagonGrid />
		</>
	);
}
