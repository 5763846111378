import React from "react";

function CornerCurve({ side, size, check1, check2 }) {
	let path = "";
	if (side === "left") {
		path = "M 100 0 Q 100 100, 0 100 T 100 100, 100 0";
	} else if (side === "right") {
		path = "M 0 0 Q 0 100, 100 100 T 0 100, 0 0";
	}

	return (
		<svg
			style={{
				width: `${size}`,
				left: `${side === "left" && `-${size}`}`,
				right: `${side === "right" && `-${size}`}`,
				opacity: `${check1 !== check2 && "0"}`,
			}}
			viewBox='0 0 100 100'
			className='absolute bottom-0'
		>
			<path d={path} className='fill-primary-6 dark:fill-secondary-5' />
		</svg>
	);
}

export default CornerCurve;
