import { React, useState } from "react";
import { useGlobalContext } from "../context";
import Navbar from "../components/Navbar";
import { Link } from "react-router-dom";
import { FaDiceD6 } from "react-icons/fa";

export default function About() {
	const { socials } = useGlobalContext();

	// eslint-disable-next-line
	const [skills, setSkills] = useState([
		[
			"Programming",
			[
				"React.js",
				"Javascript",
				"HTML",
				"CSS",
				"Git",
				"C",
				"REST API",
				"Node.js",
				"Python",
			],
		],
		[
			"Technical",
			[
				"Headless CMS",
				"UI/UX",
				"Docker",
				"Linux",
				"Networking",
				"Circuit Design",
			],
		],
		["General", ["Patient", "Organized", "Communication"]],
	]);
	// eslint-disable-next-line
	const [education, setEducation] = useState([
		"Texas A&M University, College Station, TX",
		[
			"Bachelor of Science in Electronic Systems Engineering Technology",
			"Minor in Cybersecurity",
			"May 2021",
		],
		"Circuit Design, Microcontroller Programming, Network Systems & Security, Statistics, Mathematics, Engineering, Project Management",
	]);
	// eslint-disable-next-line
	const [experience, setExperience] = useState([
		{
			company: "KNB Racing",
			title: "Front-End Engineer",
			date: "June 2021 - Present",
			location: "Remote",
			description: [
				"Developed knbracing.com, a mobile optimized web application to showcase an individual’s racing information including updatable articles, a history of notable races, sponsor information, and methods for contact via API",
				"Designed using React.js for layout and a headless CMS (Contentful) for content management and CI (continuous integration)",
				"Optimized and tested for an equivalent desktop and mobile experience as a PWA (Progressive Web App)",
				"Uses 5 sets of user updatable image, video, or text based content, and contains 4 content pages",
			],
			link: "https://dannyharris.info/projects/KNB%20Racing",
		},
		{
			company: "Texas Cheer Allstars",
			title: "Front-End Engineer",
			date: "Sept 2021 - Sept 2022",
			location: "Remote",
			description: [
				"Updated, improved, and maintained texascheerallstars.com, the TCA website using WordPress and constant communication with the client to better interpret their vision",
				"Streamlined page design for ease of consistent updating and a better mobile user experience",
				"Eliminated redundant content, reducing page and backend bloat by 25%",
				"Redesigned company logos with over 25 variations for different mediums with Figma",
				"Rendered 4 animations of the redesigned logos in Blender",
			],
			link: "https://dannyharris.info/projects/Texas%20Cheer%20Allstars",
		},
		{
			company: "Texas A&M University",
			title: "Lead Software Engineer",
			date: "Aug 2020 - May 2021",
			location: "College Station, TX",
			description: [
				"Worked in a 4-person team as the frontend developer for the University’s Capstone Project that collected data from a race car in real-time so that a secondary user could advise the driver on their performance during practice",
				"Designed a web page using JavaScript / HTML5 / CSS to receive and display JSON data from a microcontroller using a Node.js based WebSocket server connection 20 times per second",
				"Implemented live graphing with track creation from received data, and the comparison of 2 datasets",
				"Utilized Firebase Realtime Database to store thousands of collected data points",
			],
			link: "https://dannyharris.info/projects/Autotronix",
		},
	]);

	return (
		<>
			<Navbar data={{ title: "About", photo: true, logo: true }} />
			<main className='container mx-auto grid gap-6 p-6 xl:flex xl:flex-wrap'>
				{/* Intro */}
				<article className='flex flex-col items-stretch gap-6 pt-0 md:pt-9 xl:w-1/2'>
					<h1 className='text-primary-4'>Danny Harris</h1>
					<p className='text-xl font-bold'>
						Motivated front-end engineer with a background in
						electronic systems from Texas A&M University.
					</p>
					<p>
						I'm an experienced React developer with a strong
						background in creating mobile-optimized, responsive web
						applications using JavaScript frameworks. Skilled in
						collaborating with teams and working independently,
						quickly learning new technologies and incorporating
						thoughtful modern UX design into projects.
					</p>
					<p>
						Seeking a full-stack or front-end engineer position to
						develop meaningful and user-friendly digital products in
						the areas of web development, software development,
						database management, and design.
					</p>
					<p>
						My
						<Link
							to='/projects'
							className='text-primary-4 dark:text-primary-5'
						>
							{" "}
							projects{" "}
						</Link>
						are available for your review.
					</p>

					{/* Socials */}
					<div className='flex justify-center gap-6 lg:justify-center'>
						{socials.map((social, index) => {
							const { title, url } = social;

							return (
								<div key={index}>
									<a href={url}>
										<button className='btn btn-cta'>
											{title}
										</button>
									</a>
								</div>
							);
						})}
					</div>
				</article>

				{/* Skills */}
				<article className='card flex flex-col gap-3 p-6 xl:flex-grow'>
					<h2 className='text-primary-4'>Skills</h2>
					<div className='flex flex-col justify-between gap-3 md:flex-row'>
						{skills.map((category, index) => {
							const [title, list] = category;
							return (
								<div
									className='flex flex-col gap-3'
									key={index}
								>
									<b>{title}</b>
									<ul>
										{list.map((skill, index) => {
											return (
												<li key={index}>
													<FaDiceD6 className='mx-3 inline-flex items-baseline' />
													{skill}
												</li>
											);
										})}
									</ul>
								</div>
							);
						})}
					</div>
				</article>

				{/* Education */}
				<article className='card flex flex-col gap-3 p-6 xl:flex-grow'>
					<h2 className='text-primary-4'>Education</h2>
					<b>{education[0]}</b>
					<ul>
						{education[1].map((detail, index) => {
							return (
								<li key={index} className='flex'>
									<FaDiceD6 className='mx-3 mt-2 inline-flex min-w-max items-baseline' />
									<p>{detail}</p>
								</li>
							);
						})}
					</ul>
					<p>
						<b>Relevant Coursework:</b> {education[2]}
					</p>
				</article>

				{/* Experience */}
				<article className='card flex flex-col gap-3 p-6 xl:flex-grow'>
					<h2 className='text-primary-4'>Experience</h2>
					<ul className='flex flex-col  gap-3'>
						{experience.map((project, index) => {
							return (
								// Project
								<li key={index} className='group'>
									<div className='flex flex-col md:flex-row'>
										<p className='flex flex-col md:w-1/2'>
											<b>
												{project.title} at{" "}
												{project.company}
											</b>
										</p>
										<p className='flex flex-col md:w-1/2 md:items-end'>
											{project.date}
										</p>
									</div>

									{/* Project description bullet points */}
									<ul className='py-3'>
										{project.description.map(
											(descriptor, index) => {
												return (
													<li
														key={index}
														className='flex'
													>
														<FaDiceD6 className='mx-3 mt-2 inline-flex min-w-max items-baseline' />
														<p>{descriptor}</p>
													</li>
												);
											}
										)}
									</ul>

									{/* Link to more info on project */}
									<div className='inline-block w-full border-b pb-3 text-center group-last:border-none group-last:pb-0 dark:border-secondary-2 md:text-left'>
										<a
											href={project.link}
											className='text-primary-4 dark:text-primary-5'
										>
											Learn More
										</a>
									</div>
								</li>
							);
						})}
					</ul>
				</article>
			</main>
		</>
	);
}
