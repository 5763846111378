import { React, useState } from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../context";

export default function ProjectCard({ data }) {
	const { title, shortDescription, tags, images } = data.fields;
	const { isMobile } = useGlobalContext();

	const [cardHover, setCardHover] = useState(false);

	return (
		<Link
			to={`/projects/${title}`}
			style={{
				backgroundImage: `url(${images[0].fields.file.url}?w=800&h=600&fm=webp)`,
			}}
			className='anim-grow-fade group relative h-72 w-full cursor-pointer overflow-hidden rounded-xl bg-primary-1 bg-cover bg-center text-primary-6 shadow-inner shadow-primary-1'
			onMouseEnter={() => setCardHover(true)}
			onMouseLeave={() => setCardHover(false)}
			onClick={() => {
				window.scrollTo({
					top: 0,
					behavior: "smooth",
				});
			}}
		>
			{/* titlebar */}
			<h2
				className={`${
					cardHover || isMobile ? "anim-fade-out" : "anim-fade-in"
				} anim-fast bg-primary-1 bg-opacity-50 p-3 text-center text-primary-6 shadow-none shadow-primary-1 backdrop-blur`}
			>
				{title}
			</h2>

			{/* Hover/mobile info */}
			<div
				className={`${
					cardHover || isMobile ? "anim-fade-in" : "anim-fade-out"
				} anim-fast absolute top-0 left-0 flex h-full w-full flex-col justify-center gap-3 bg-primary-2 bg-opacity-70 p-6 backdrop-blur`}
			>
				{/* project title */}
				<h2 className='w-full text-center text-primary-5'>{title}</h2>

				{/* project description */}
				<p className='text-center'>{shortDescription}</p>

				{/* project tags */}
				<div className='flex flex-row flex-wrap justify-center gap-3'>
					{tags.map((tag, index) => {
						return (
							<span
								key={index}
								className='rounded bg-primary-6 px-3 text-primary-1'
							>
								{tag}
							</span>
						);
					})}
				</div>
				<div className='absolute top-0 left-0 h-full w-2/3 translate-x-1/4 -skew-x-[24deg] bg-gradient-to-tr from-transparent via-primary-6 opacity-10 duration-300 group-hover:translate-x-1/4 md:translate-x-0'></div>
			</div>
		</Link>
	);
}
