import React from "react";

const HexagonGrid = () => {
	return (
		<svg
			viewBox='0 0 438 438'
			className={`fixed top-20 left-0 -z-10 w-full fill-none stroke-[4] opacity-10 md:rotate-180 md:stroke-2 md:opacity-10`}
		>
			<g>
				<path
					d='M271.152 31.0951L250.335 67.0332L271.051 102.913L312.582 102.854L333.399 66.9159L312.684 31.0364L271.152 31.0951Z'
					stroke='gray'
				/>
				<path
					d='M333.4 66.9172L312.583 102.855L333.298 138.735L374.83 138.676L395.646 102.738L374.931 66.8586L333.4 66.9172Z'
					stroke='gray'
				/>
				<path
					d='M271.051 102.914L250.235 138.852L270.95 174.732L312.481 174.673L333.298 138.735L312.583 102.855L271.051 102.914Z'
					stroke='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 272.257 26.9968)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 251.44 62.9337)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 272.157 98.8159)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 313.687 98.7559)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 334.505 62.819)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 313.788 26.9369)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 251.339 134.753)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99654'
					rx='3.00633'
					ry='2.99654'
					transform='matrix(-0.866025 0.5 0.5 0.866025 272.056 170.635)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99654'
					rx='3.00633'
					ry='2.99654'
					transform='matrix(-0.866025 0.5 0.5 0.866025 334.404 134.638)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 313.587 170.575)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 375.935 134.578)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 376.036 62.7591)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99654'
					rx='3.00633'
					ry='2.99654'
					transform='matrix(-0.866025 0.5 0.5 0.866025 396.752 98.6412)'
					fill='gray'
				/>
				<path
					d='M59.4502 160.48L38.6336 196.418L59.3487 232.298L100.88 232.239L121.697 196.301L100.982 160.421L59.4502 160.48Z'
					stroke='gray'
				/>
				<path
					d='M121.698 196.302L100.881 232.24L121.596 268.12L163.128 268.061L183.945 232.123L163.229 196.244L121.698 196.302Z'
					stroke='gray'
				/>
				<path
					d='M59.3494 232.299L38.5327 268.237L59.2478 304.117L100.779 304.058L121.596 268.12L100.881 232.24L59.3494 232.299Z'
					stroke='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 60.5555 156.382)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 39.7381 192.319)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 60.4546 228.201)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 101.986 228.141)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 122.803 192.204)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 102.086 156.322)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 39.6372 264.138)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99654'
					rx='3.00633'
					ry='2.99654'
					transform='matrix(-0.866025 0.5 0.5 0.866025 60.3538 300.02)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99654'
					rx='3.00633'
					ry='2.99654'
					transform='matrix(-0.866025 0.5 0.5 0.866025 122.702 264.023)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 101.885 299.96)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 164.233 263.963)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 164.334 192.144)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99654'
					rx='3.00633'
					ry='2.99654'
					transform='matrix(-0.866025 0.5 0.5 0.866025 185.05 228.026)'
					fill='gray'
				/>
				<path
					d='M276.45 272.48L255.634 308.418L276.349 344.298L317.88 344.239L338.697 308.301L317.982 272.421L276.45 272.48Z'
					stroke='gray'
				/>
				<path
					d='M338.698 308.302L317.881 344.24L338.596 380.12L380.128 380.061L400.945 344.123L380.229 308.244L338.698 308.302Z'
					stroke='gray'
				/>
				<path
					d='M276.349 344.299L255.533 380.237L276.248 416.117L317.78 416.058L338.596 380.12L317.881 344.24L276.349 344.299Z'
					stroke='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 277.555 268.382)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 256.738 304.319)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 277.455 340.201)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 318.986 340.141)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 339.803 304.204)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 319.086 268.322)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 256.637 376.138)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99654'
					rx='3.00633'
					ry='2.99654'
					transform='matrix(-0.866025 0.5 0.5 0.866025 277.354 412.02)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99654'
					rx='3.00633'
					ry='2.99654'
					transform='matrix(-0.866025 0.5 0.5 0.866025 339.702 376.023)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 318.885 411.96)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 381.233 375.963)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99653'
					rx='3.00633'
					ry='2.99653'
					transform='matrix(-0.866025 0.5 0.5 0.866025 381.334 304.144)'
					fill='gray'
				/>
				<ellipse
					cx='3.00633'
					cy='2.99654'
					rx='3.00633'
					ry='2.99654'
					transform='matrix(-0.866025 0.5 0.5 0.866025 402.05 340.026)'
					fill='gray'
				/>
			</g>
		</svg>
	);
};

export default HexagonGrid;
